import React, { useContext } from 'react'
import { graphql, navigate, Link } from "gatsby"
import { GlobalDispatchContext } from '../context/GlobalContextProvider';
import Layout from '../components/layout/Layout.component'
import Seo from '../components/Seo.component'
// import { InView } from 'react-intersection-observer';
import Hero from '../components/hero/Hero.component';
import ComparisonSection from '../components/comparisonSection/ComparisonSection.component';
import { ButtonLink } from '../components/buttons/Button.component';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { CarouselProducts } from '../components/carousel/Carousel.component';
import SocialImgs from '../components/socialImgs/SocialImgs.component';
import SectionIcons from '../components/sectionIcons/SectionIcons.component';
import './index-page.styles.scss';

const IndexPageTemplate = ({
    title,
    heroImage,
    backgroundHero,
    heading,
    subheading,
    mainTangled,
    mainOrganized,
    products,
    optionImg1,
    optionImg2,
    optionImg3,
    optionImg4,
    optionImg5,
    collectionImg_1_1,
    collectionImg_1_2,
    collectionImg_2_1,
    collectionImg_2_2,
    showCase1,
    showCase2,
    showCase3,
    socialImg1,
    socialImg2,
    socialImg3,
    socialImg4,
}) => {

  const dispatch = useContext(GlobalDispatchContext);

  // Handle corresponding selection from option picker to navigate to correct variant on product page
  async function handleColorSelect(color, location){
    await dispatch({
      type: 'COLOR_SELECT',
      payload: color
    })
    navigate(`${location}`);
  }

  async function inlineHandleColorSelect(color){
    await dispatch({
      type: 'COLOR_SELECT',
      payload: color
    })
  }

  async function handleKeyUp(ev, color, location){
    if(ev.key === 'Enter'){
      await dispatch({
        type: 'COLOR_SELECT',
        payload: color
      })
      navigate(`${location}`);
    }
  }

  async function handleKeyUpInline(ev, color){
    if(ev.key === 'Enter'){
      await dispatch({
        type: 'COLOR_SELECT',
        payload: color
      })
    }
  }


  // Data to be passed to the OptionPicker component
  const optionsData = [
    {img: optionImg5, color: 'Red Delicious', alt: 'Red Delicious Applecore 3 Pack'},
    {img: optionImg2, color: 'Black Jazz', alt: 'Black Jazz Applecore 3 Pack'}, 
    {img: optionImg1, color: 'Granny Smith', alt: 'Granny Smith Applecore 3 Pack'}, 
    {img: optionImg4, color: 'Fuji White', alt: 'Fuji White Applecore 3 Pack'}, 
    {img: optionImg3, color: 'Blue Pearmain', alt: 'Blue Pearmain Applecore 3 Pack'}, 
  ]


  // Data to be passed to the SocialImg component
  const socialHeader = `Show us your Applecores`;
  const socialText = (<p>Tag us in your photos using <a href="https://www.instagram.com/explore/tags/applecore/" className="accent" target="_blank" rel="noreferrer">#applecore</a> and <a href="https://www.instagram.com/explore/tags/nomoretangles/" className="accent" target="_blank" rel="noreferrer">#nomoretangles</a> to be featured</p>);
  const socialData = [
    { 
      socialImg: socialImg1,
      alt: `Applecore Instagram picture 1`,
      link: `https://www.instagram.com/p/CJY2PlZMEMn/`
    },
    {
      socialImg: socialImg2,
      alt: `Applecore Instagram picture 2`,
      link: `https://www.instagram.com/p/CIybZpFsgG7/`
    },
    {
      socialImg: socialImg3,
      alt: `Applecore Instagram picture 3`,
      link: `https://www.instagram.com/p/CIyZNc4skqx/`
    },
    {
      socialImg: socialImg4,
      alt: `Applecore Instagram picture 4`,
      link: `https://www.instagram.com/p/CIwyyTxMbmV/`
    }
  ];

    return(
        <div>
            <Seo
              title="Home"
              url="https://www.myapplecore.com/"
            />
            
            <Hero
              heading={heading}
              subheading={subheading}
              heroImage={heroImage}
              //backgroundHero={backgroundHero} uncomment for Hero1
            />

            <div className="anchor" id="seemore" tabIndex="-1" aria-hidden="true"></div>
            <ComparisonSection 
              mainTangled={mainTangled}
              mainOrganized={mainOrganized}
            />

            <section className={`section-container home-product-options`}>
              <h2><span className={`brush-fit`}>Select a color</span> to match your wires</h2>
              <CarouselProducts
                  items={ 
                    optionsData.map(option => (
                        <div key={option.color} role="button" aria-label="View Product" tabindex="0" className="color-option-img product-img-wrap" onClick={() => handleColorSelect(`${option.color}`, `/shop/applecore-3-pack/`)} onKeyUp={(ev) => handleKeyUp(ev, `${option.color}`, `/shop/applecore-3-pack/`)} title={`View Product`}>
                          <PreviewCompatibleImage  
                              imageInfo={{
                                  image: option.img,
                                  alt: option.alt, 
                              }}
                          />
                          <p>{option.color}</p>
                        </div>
                    )) }
              />
              <div>
                <p className="carousel-text" style={{ color: '#C2D6EA' }}>The Applecore comes in various colors to complement your cords. Use them to color coordinate your wires and avoid unorganized tangles. </p>
                <ButtonLink toLink={`/shop/applecore-3-pack/`} style={{marginTop: '3em'}}>
                    <span>Buy Now</span>
                </ButtonLink>
              </div> 
            </section>

            <SectionIcons />

            <section className={`section-container home-product-collections`}>
              <div className="collections-grid">
                <div className="collections-text">
                  <h2 style={{color: '#ffffff', marginBottom: '1.25em'}}><span className={`brush-wide`}>Collections</span></h2>
                  <p style={{maxWidth: '40ch', color: '#C2D6EA', margin: '0 auto' }}>Our most popular items. Select an Applecore collection to get deals on color matching sets. </p>
                </div>
              
                <div role="button" aria-label="Select Black and White" tabindex="0" className="collection-option-img collection-2 product-img-wrap" onClick={() => handleColorSelect(`Black - White`, `/shop/collections/`)} onKeyUp={(ev) => handleKeyUp(ev, `Black - White`, `/shop/collections/` )} title={`Select Black and White`}>
                  <PreviewCompatibleImage 
                      imageInfo={{
                          image: collectionImg_2_1,
                          alt: 'Black Applecore for Collection',
                      }}
                      className="collection-2-1"
                  />
                  <PreviewCompatibleImage 
                      imageInfo={{
                          image: collectionImg_2_2,
                          alt: 'White Applecore for Collection',
                      }}
                      className="collection-2-2"
                  />
                </div>
                <p className="collection-1-link"><Link to={`/shop/collections/`} onClick={() => inlineHandleColorSelect(`Black - White`)} onKeyUp={(ev) => handleKeyUpInline(ev, `Black - White`)}>Black and White</Link></p>


                <div role="button" aria-label="Select Pink and Purple" tabindex="0" className="collection-option-img collection-1 product-img-wrap" onClick={() => handleColorSelect(`Pink - Purple`, `/shop/collections/`)} onKeyUp={(ev) => handleKeyUp(ev, `Pink - Purple`, `/shop/collections/` )} title={`Select Pink and Purple`}>
                  <PreviewCompatibleImage 
                      imageInfo={{
                          image: collectionImg_1_1,
                          alt: 'Pink Applecore for Collection',
                      }}
                      className="collection-1-1"
                  />
                  <PreviewCompatibleImage 
                      imageInfo={{
                          image: collectionImg_1_2,
                          alt: 'Purple Applecore for Collection',
                      }}
                      className="collection-1-2"
                  />
                </div>
                <p className="collection-2-link"><Link to={`/shop/collections/`} onClick={() => inlineHandleColorSelect(`Pink - Purple`)} onKeyUp={(ev) => handleKeyUpInline(ev, `Pink - Purple`)}>Pink and Purple</Link></p>
             </div> 
            </section>

            <section className={`section-container section-quotes`}>
                  <q className="home-quote"><span>My cords are now manageable!</span><br/> It's like a whole new world underneath my desk.  I could find all kinds of uses for these such as lamps, kitchen appliances, radios, and TVs” - Nancy</q>
                  <q className="home-quote" style={{marginTop: '8%'}}><span>I love my Applecore!</span><br/>We use them for headphones, phone cords, computer mouse and keyboard cords, and appliances in the kitchen. My kitchen counter is so tidy and I don't have to constantly try to shove the cord behind an appliance” - Akedy</q>
            </section>

            <section className={`section-container function-section`}>
                  <div className="function-section-flex">
                    <div className="function-imgs-wrap">
                      <div role="button" aria-label="View Product" tabindex="0" className="function-option-img" style={{width: '55%'}} onClick={() => handleColorSelect(`Red Delicious`, `/shop/applecore-3-pack/`)} onKeyUp={(ev) => handleKeyUp(ev, `Red Delicious`, `/shop/applecore-3-pack/`)} title={`View Product`}>
                        <PreviewCompatibleImage 
                            imageInfo={{
                                image: showCase1,
                                alt: 'Red Applecore 3 Pack Option',
                            }}
                        />
                      </div>
                      <div role="button" aria-label="View Product" tabindex="0" className="function-option-img" style={{width: '55%'}} onClick={() => handleColorSelect(`Black Jazz`, `/shop/applecore-3-pack/`)} onKeyUp={(ev) => handleKeyUp(ev, `Black Jazz`, `/shop/applecore-3-pack/`)} title={`View Product`}>
                        <PreviewCompatibleImage 
                            imageInfo={{
                                image: showCase2,
                                alt: 'Black Applecore 3 Pack Option',
                            }}
                        />
                      </div>
                      <div role="button" aria-label="View Product" tabindex="0" className="function-option-img" style={{width: '55%'}} onClick={() => handleColorSelect(`Granny Smith`, `/shop/applecore-3-pack/`)} onKeyUp={(ev) => handleKeyUp(ev, `Granny Smith`, `/shop/applecore-3-pack/`)} title={`View Product`}>
                        <PreviewCompatibleImage 
                            imageInfo={{
                                image:  showCase3,
                                alt: 'Green Applecore 3 Pack Option',
                            }}
                        />
                      </div>
                    </div>
                    
                    <div className="function-text">
                      <h2 style={{color: '#ffffff'}}>Practical Organization</h2>
                      <p style={{color: '#C2D6EA', maxWidth: '60ch', margin: '0 auto'}}>Keep your wires accessible and organized wherever you need them. Gone are the days of cluttered desks and messy cables.</p>
                    </div>
                  </div>
            </section>

            <SocialImgs
              socialHeader={socialHeader}
              socialText={socialText}
              socialData={socialData}
            />

            <section className={`section-container alternate-products`}>
                  <div>
                    <h2>Pick your Applecores</h2>
                    <p>Select below the applecore pack that best fits your needs!</p>
                  </div>
                  
                  <div className="alternate-flex">
                      {products.map(product => (
                        <ButtonLink key={product.title} toLink={`/shop/${product.handle}/`} className={`alt-prod-btn`}>
                          <span>{product.title}</span>
                        </ButtonLink>
                      ))}
                  </div> 
            </section>
        </div>
    )
}

const IndexPage = ({ data }) => {
    const { allShopifyProduct: { nodes: products } } = data
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <IndexPageTemplate
                products={products}
                title={frontmatter.title}
                heroImage={frontmatter.heroImage}
                backgroundHero={frontmatter.backgroundHero}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainTangled={frontmatter.mainTangled}
                mainOrganized={frontmatter.mainOrganized}
                optionImg1={frontmatter.optionImg1}
                optionImg2={frontmatter.optionImg2}
                optionImg3={frontmatter.optionImg3}
                optionImg4={frontmatter.optionImg4}
                optionImg5={frontmatter.optionImg5}
                collectionImg_1_1={frontmatter.collectionImg_1_1}
                collectionImg_1_2={frontmatter.collectionImg_1_2}
                collectionImg_2_1={frontmatter.collectionImg_2_1}
                collectionImg_2_2={frontmatter.collectionImg_2_2}
                showCase1={frontmatter.showCase1}
                showCase2={frontmatter.showCase2}
                showCase3={frontmatter.showCase3}
                socialImg1={frontmatter.socialImg1}
                socialImg2={frontmatter.socialImg2}
                socialImg3={frontmatter.socialImg3}
                socialImg4={frontmatter.socialImg4}
            />
        </Layout>
    )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query homeQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter{
            title
            heroImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            backgroundHero {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            heading
            subheading
            mainTangled {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mainOrganized {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            optionImg1 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            optionImg2 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            optionImg3 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            optionImg4 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            optionImg5 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            collectionImg_1_1 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            collectionImg_1_2 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            collectionImg_2_1 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            collectionImg_2_2 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            showCase1 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            showCase2 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            showCase3 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            socialImg1 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            socialImg2 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            socialImg3 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            socialImg4 {
              childImageSharp {
                fluid(maxWidth: 850, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
    }
    allShopifyProduct(sort: {fields: id}) {
      nodes {
        title
        handle
      }
    }
  }
`

/** replace below variants query to include 'compareAtPrice' if added discount to show on site */
// variants {
//   compareAtPrice
//   price
//   selectedOptions {
//     name
//     value
//   }
// }


/** add below query 'handle' */
// images {
//   localFile {
//     childImageSharp {
//       fluid(maxWidth: 290) {
//         ...GatsbyImageSharpFluid_noBase64
//       }
//     }
//   }
// }
// variants {
//   price
//   selectedOptions {
//     name
//     value
//   }
// }
// priceRange {
//   maxVariantPrice {
//     amount
//     currencyCode
//   }
//   minVariantPrice {
//     amount
//     currencyCode
//   }
// }