import React from 'react';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { ButtonLink } from '../buttons/Button.component';
import './hero.styles.scss';

const Hero = ({ heroImage, heading, subheading }) => {
    return (
            <section className={`section-container hero`} style={{position: 'relative'}}>
                <div className="hero-img-wrap">
                    <div className="hero-img">
                      <PreviewCompatibleImage 
                        loading={"eager"}
                        imageInfo={{
                            image: heroImage,
                            alt: 'Applecore Cord Organizer',
                        }}
                      />
                    </div> 
                </div>

                <div className="landing-grid">
                  <div className="hero-copy">
                    <h1>{heading}</h1>
                    <p>{subheading}</p>
                    <ButtonLink toLink={`/shop/`} className={`hero-btn`} >
                      <span>Get Organized</span>
                    </ButtonLink> 
                  </div>  
                </div>
                  
                <div className={`triangle-down `}>
                  <span><a href="#seemore" style={{color: '#FBFBFB'}}>See Before/After</a></span>
                </div>
            </section>
    )
}

export default Hero;

